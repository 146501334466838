// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body {
  margin: 0;
}
canvas {
  display: block;
  vertical-align: bottom;
} 
#particles-js {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  
}
.count-particles {
  background: #000022;
  position: absolute;
  top: 48px;
  left: 0;
  width: 80px;
  color: #13e8e9;
  font-size: 0.8em;
  text-align: left;
  text-indent: 4px;
  line-height: 14px;
  padding-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.js-count-particles {
  font-size: 1.1em;
}
#stats,
.count-particles {
  -webkit-user-select: none;
  margin-top: 5px;
  margin-left: 5px;
}
#stats {
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}
.count-particles {
  border-radius: 0 0 3px 3px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,SAAS;AACX;AACA;EACE,cAAc;EACd,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,4BAA4B;EAC5B,sBAAsB;EACtB,4BAA4B;;AAE9B;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,WAAW;EACX,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,yCAAyC;EACzC,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;;EAEE,yBAAyB;EACzB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,0BAA0B;EAC1B,gBAAgB;AAClB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":["\r\nbody {\r\n  margin: 0;\r\n}\r\ncanvas {\r\n  display: block;\r\n  vertical-align: bottom;\r\n} \r\n#particles-js {\r\n  position: fixed;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-image: url(\"\");\r\n  background-repeat: no-repeat;\r\n  background-size: cover;\r\n  background-position: 50% 50%;\r\n  \r\n}\r\n.count-particles {\r\n  background: #000022;\r\n  position: absolute;\r\n  top: 48px;\r\n  left: 0;\r\n  width: 80px;\r\n  color: #13e8e9;\r\n  font-size: 0.8em;\r\n  text-align: left;\r\n  text-indent: 4px;\r\n  line-height: 14px;\r\n  padding-bottom: 2px;\r\n  font-family: Helvetica, Arial, sans-serif;\r\n  font-weight: bold;\r\n}\r\n.js-count-particles {\r\n  font-size: 1.1em;\r\n}\r\n#stats,\r\n.count-particles {\r\n  -webkit-user-select: none;\r\n  margin-top: 5px;\r\n  margin-left: 5px;\r\n}\r\n#stats {\r\n  border-radius: 3px 3px 0 0;\r\n  overflow: hidden;\r\n}\r\n.count-particles {\r\n  border-radius: 0 0 3px 3px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
