// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes marquee-left {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-20%);
    }
  }
  
  @keyframes marquee-right {
    from {
      transform: translateX(-20%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .animate-marquee-left {
    animation: marquee-left 20s linear infinite;
  }
  
  .animate-marquee-right {
    animation: marquee-right 20s linear infinite;
  }
  
  /* Pause animations when user prefers reduced motion */
  @media (prefers-reduced-motion: reduce) {
    .animate-marquee-left,
    .animate-marquee-right {
      animation: none;
    }
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .animate-marquee-left,
    .animate-marquee-right {
      animation-duration: 15s;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/MarqueeGallery.css"],"names":[],"mappings":"AAAA;IACI;MACE,wBAAwB;IAC1B;IACA;MACE,2BAA2B;IAC7B;EACF;;EAEA;IACE;MACE,2BAA2B;IAC7B;IACA;MACE,wBAAwB;IAC1B;EACF;;EAEA;IACE,2CAA2C;EAC7C;;EAEA;IACE,4CAA4C;EAC9C;;EAEA,sDAAsD;EACtD;IACE;;MAEE,eAAe;IACjB;EACF;;EAEA,2BAA2B;EAC3B;IACE;;MAEE,uBAAuB;IACzB;EACF","sourcesContent":["@keyframes marquee-left {\n    from {\n      transform: translateX(0);\n    }\n    to {\n      transform: translateX(-20%);\n    }\n  }\n  \n  @keyframes marquee-right {\n    from {\n      transform: translateX(-20%);\n    }\n    to {\n      transform: translateX(0);\n    }\n  }\n  \n  .animate-marquee-left {\n    animation: marquee-left 20s linear infinite;\n  }\n  \n  .animate-marquee-right {\n    animation: marquee-right 20s linear infinite;\n  }\n  \n  /* Pause animations when user prefers reduced motion */\n  @media (prefers-reduced-motion: reduce) {\n    .animate-marquee-left,\n    .animate-marquee-right {\n      animation: none;\n    }\n  }\n  \n  /* Responsive adjustments */\n  @media (max-width: 768px) {\n    .animate-marquee-left,\n    .animate-marquee-right {\n      animation-duration: 15s;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
