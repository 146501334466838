
import Component from './Home';

function App() {
  
  return (
    <div className="App relative">
     <Component />
        <div id="particles-js" className=" inset-0 z-0" />
    
    </div>
  );
}

export default App;
