// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,iCAAiC,EAAE,4BAA4B;EACjE","sourcesContent":["html {\n    scroll-behavior: smooth;\n    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
